.loading_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;

  height: 100%;
  width: 100%;
}

.lottie_container {
  max-height: 200px;
  max-width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading_text {
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, system-ui,
    Roboto, 'Helvetica Neue', 'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR',
    'Malgun Gothic', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    sans-serif;
}
