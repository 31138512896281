.btnCalendar .week {
  width: auto;
}
.rs-picker-date-menu .rs-calendar-table-cell-week-number {
  background-color: #a5a6a9;
  color: #fff;
  padding: 10px;
  font-weight: 700;
}

.rs-picker-date-menu
  .rs-calendar-table-row:hover
  .rs-calendar-table-cell-content {
  background-color: #d2d2d4 !important;
  color: white !important;
}

.rs-picker-date-menu
  .rs-calendar-table-row:not(.rs-calendar-table-header-row):has(
    .rs-calendar-table-cell-selected
  )
  .rs-calendar-table-cell-content {
  background-color: #1f2028;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
}

.rs-btn-primary {
    background-color: #1f2028 !important;
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: #35363e;
}

.rs-picker .rs-picker-toggle:hover {
  border-color: #1f2028 !important;
}
.rs-picker .rs-picker-toggle:focus {
  border-color: #1f2028 !important;
  box-shadow: none;
}
